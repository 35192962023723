import { DateTime } from "luxon"
import axios from "axios"
import Util from "packs/utils/Util"
import { funcName, Logger } from "packs/common"
import Notice from "./Notice"
import Room from "./Room"
import PossibleDate from "./PossibleDate"
import RoomMember from "./RoomMember"
import MeetingAttendeesGroup from "./MeetingAttendeesGroup"
import LocationTag from "./LocationTag"
import NotificationControl from "packs/utils/NotificationControl"
import CalendarTag from "./CalendarTag"
import DateTag from "./DateTag"
import lodash from "lodash"

interface OnlineMeetingLocation {
    online_meeting_url: string
    online_meeting_id: string
    online_meeting_password: string
    is_owner_ug: boolean
    online_meeting_account_email: string
    online_meeting_zoom_host_key: string
}

export default class Appointment {
    static readonly typeEdit: string = "editAppo"
    static readonly typeEditAfterFixed: string = "editAppoAfterFixed"
    static readonly typeEditAfterCanceled: string = "editAppoAfterCanceled"
    static readonly typeAddNew: string = "addnewAppo"
    static readonly typeNew: string = "newAppo"
    static readonly typeReschedule: string = "reschedule"
    static readonly typeCancel: string = "cancel"

    static readonly statusInProgress: number = 1
    static readonly statusFix: number = 10
    static readonly statusCancel: number = -1

    static readonly DEFAULT_DOWNLOAD_STATUSES = [
        { name: `in_progress`, title: `調整中`, num: 1, checked: true },
        { name: `fixed`, title: `調整済`, num: 10, checked: true },
        { name: `canceled`, title: `キャンセル`, num: -1, checked: true },
    ]

    constructor(
        public name: string,
        public status: number,
        public id: string,
        public duration: number, // min
        public location_name: string,
        public created_at: number,
        public updated_at: number,
        public expired_at: number,
        public memo: string,
        public required_owner_participation: boolean, // chukaiが参加するかどうか
        public start_time: number,
        public end_time: number,
        public jp_format: string,
        public fix_type: number, // 日程確定可能者 オーナーのみ=1, オーナーと調整カレンダー最後のユーザー=99, 誰でも=100
        public selected_mag_dic: { [key: string]: MeetingAttendeesGroup },
        public selectedMags: MeetingAttendeesGroup[],
        public location_select_type: string, //registrable, registrable_owner_only, selectable, selectable_or_registrable
        public location_tag_id: string, // optional
        public selectable_location_tag_ids: string[],
        public selected_location: LocationTag,
        public selectable_locations: LocationTag[],
        // public locations: LocationTag[],
        public is_selected_free_location: boolean,
        public localId: string,
        public is_online_meeting: boolean,
        public online_meeting: OnlineMeetingLocation,
        public can_cancel: boolean,
        public cancel_expires_at: number,
        public can_display_mag_in_room: boolean
    ) {}

    static copy(o: Appointment): Appointment {
        let newO = lodash.cloneDeep(o)
        return newO
    }

    static fetchFromJson(apposJson: any): Appointment[] {
        if (!apposJson) return []
        let appointments = []
        // const arrOfGroups = JSON.parse(groupsJson)
        for (let appo of apposJson) {
            if (Util.isBlank(appo)) continue
            const _appo: Appointment = appo
            if (appo.selected_mag_dic) {
                appo.selectedMags = [...Object.values(appo.selected_mag_dic)]
            }
            if (appo.status == 10) {
            } else {
                if (appo.selected_location) {
                    let loctag = LocationTag.fetchFromJson([appo.selected_location])[0]
                    if (loctag) _appo.location_name = loctag.keyword
                    _appo.selected_location = loctag
                }
                if (appo.selectable_locations) {
                    let loctags = LocationTag.fetchFromJson(appo.selectable_locations)
                    if (_appo.selected_location) {
                        let _loctag = loctags.find(l => l.public_id == _appo.selected_location.public_id)
                        if (_loctag) _loctag.selected = true
                    }

                    _appo.selectable_locations = loctags
                }
            }

            appointments.push(_appo)
        }
        return appointments
    }

    /**
     * numberに変更します.
     * @param str owner/last/any
     */
    static fixTypeFromString(str: string) {
        let fix_type
        if (str == `owner`) {
            fix_type = 1
        } else if (str == `last`) {
            fix_type = 99
        } else if (str == `any`) {
            fix_type = 100
        }
        return fix_type
    }

    static isFix(room: Room = null, appo: Appointment = null) {
        if (!room && !appo) return false
        if (!appo) {
            appo = room.current_appointment
        }

        if (!appo) return false
        if (appo.status == Appointment.statusFix) return true
        return false
    }

    static isCanceled(room: Room = null, appo: Appointment = null) {
        if (!room && !appo) return false
        if (!appo) {
            appo = room.current_appointment
        }

        if (!appo) return false
        if (appo.status == Appointment.statusCancel) return true
        return false
    }

    static createDefault(): Appointment {
        return new Appointment(
            `打ち合わせ`,
            1,
            ``,
            60,
            ``,
            0,
            0,
            0,
            ``,
            true,
            -1,
            -1,
            null,
            null,
            null,
            null,
            `registrable`,
            null,
            null,
            null,
            null,
            false,
            null,
            false,
            null,
            true,
            null,
            false
        )
    }

    /**
     * アポを作成/更新します。
     * @param appointment [Appointment]
     * @param sendType [string] "put", "post", "fix"
     * @param room [Room] 調整ページのIDを利用するため.
     * @param magId [string] MeetingAttendeesGroupのidをセットすると、そのmagチームで確定します。
     */
    static update(
        appointment: Appointment,
        sendType,
        room: Room,
        start_time = null,
        magId = null,
        guestKey = null
    ): Promise<any> {
        let endpoint = ""
        if (sendType === "fix") {
            endpoint = "/fix"
            sendType = "put"
            appointment["start_time"] = start_time
            if (magId) appointment["group_id"] = magId
            if (guestKey) {
                appointment[`guest_key`] = guestKey
                appointment[`embeds`] = true
            }
        } else if (sendType === "post") {
            appointment["room_id"] = room.room_id
            delete appointment["id"]
        } else if (sendType === `put`) {
            // 編集の場合、room_appeal_contentの変更も許可
            appointment[`appeal_content`] = room.appeal_content
        }
        if (room.isParent) {
            appointment["parent_room_id"] = room.id
        } else {
            appointment["parent_room_id"] = room.parentRoomId
        }
        if (!appointment.name || appointment.name == ``) {
            appointment.name = "打ち合わせ"
        }
        // appointment["parent_room_id"] = ""
        Logger(`Appointment.update sendType: ${sendType} appo: ${Util.output(appointment)}`)

        return axios({
            method: sendType,
            url: `${Util.prefixUrl}/room_appointments${endpoint}`,
            data: appointment,
        })
            .then(res => {
                Logger(`Appointment.update res: ${res.data.message} ${Util.output(res.data)}`)
                if (res.data.message) {
                    Notice.message = `${res.data.message}`
                }
                const res_appo: Appointment = Appointment.fetchFromJson([res.data.appointment])[0]
                let next_action = res.data.next_action
                let type = res.data.type

                return { appo: res_appo, show_attendees_modal: res.data.show_attendees_modal, next_action, type }
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                // if (res.code === "ng_052") {

                // } else {
                //     Notice.message = `日程調整情報をうまく更新できませんでした。後ほどお試しください。`
                // }
                return null
            })
    }

    /**
     * アポを作成/更新します。
     * @param appointment [Appointment]
     * @param sendType [string] "cancel", "reschedule"
     * @param message [string] ユーザーがコメントした内容.
     */
    static cancel(appointment: Appointment, sendType, message = ``): Promise<Appointment> {
        let endpoint = ""
        if (sendType === "cancel") {
            endpoint = "/cancel"
        } else if (sendType === "reschedule") {
            endpoint = "/reschedule"
        } else {
            Notice.message = "URLが不正です。"
            return
        }
        const params = { id: appointment.id, message: message }

        // appointment["parent_room_id"] = ""
        Logger(`Appointment.cancel sendType: ${sendType} appo: ${Util.output(appointment)}`)

        sendType = "put"

        return axios({
            method: sendType,
            url: `${Util.prefixUrl}/room_appointments${endpoint}`,
            data: params,
        })
            .then(res => {
                Logger(`Appointment.cancel res: ${res.data.message} ${Util.output(res.data)}`)
                if (res.data.message) {
                    Notice.message = `${res.data.message}`
                }
                const appo: Appointment = Appointment.fetchFromJson([res.data.appointment])[0]
                // const appo: Appointment = res.data.appointment

                return appo
            })
            .catch(err => {
                Logger(`Appointment.cancel err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                // if (res.code === "ng_052") {

                // } else {
                //     Notice.message = `日程調整情報をうまく更新できませんでした。後ほどお試しください。`
                // }
                return null
            })
    }

    static getLocationNames(): Promise<string[]> {
        return axios
            .get(`${Util.prefixUrl}/room_appointments/locations`)
            .then(result => {
                Logger(`Appointment.${funcName()} result: ${Util.output(result.data)}`)
                const locations: string[] = result.data.locations
                return locations
            })
            .catch(err => {
                Logger(`Appointment.${funcName()} err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static getTitles(): Promise<string[]> {
        return axios
            .get(`${Util.prefixUrl}/room_appointments/titles`)
            .then(result => {
                Logger(`Appointment.${funcName()} result: ${Util.output(result.data)}`)
                const titles: string[] = result.data.titles
                return titles
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    /**
     * appo内のlocation_select_type（registrable/selectable/selectable_or_registrable）の判定を行います。
     * 最後にappo.selectable_locationsにloctagsをセットします。
     *
     * @param appo [Appointment] 新しいloctagとタイプをセットするappo（途中で更新が走らないようにコピーして利用、返却）
     * @param loctags [LocationTag[]] 判定に利用するlocationTags
     * @param deleteFree [boolean] フリーフォームを削除するか（ルーム作成直前に通すときは削除します）
     */
    static setLocationSelectType(appo: Appointment, loctags: LocationTag[], deleteFree = false) {
        Logger(`Appointment.setLocationSelectType loctags[${(loctags || []).length}]:${Util.output(loctags)}`)

        let _appo = Appointment.copy(appo)
        let _freeLoctag = null
        if (Util.isPresent(loctags)) {
            if (loctags.length == 1) {
                _appo.location_select_type = `registrable`
            } else {
                _freeLoctag = loctags.find(l => l.public_id == `free`)
                if (_freeLoctag) {
                    _appo.location_select_type = `selectable_or_registrable`
                } else {
                    _appo.location_select_type = `selectable`
                }
            }
            Logger(`Appointment.setLocationSelectType location_select_type:${_appo.location_select_type}`)

            let loctagsExceptFree = loctags.filter(l => l.public_id != `free`)
            let defaultLoctag = loctags[0]
            if (Util.isPresent(defaultLoctag) && Util.isPresent(defaultLoctag.id)) {
                _appo.location_tag_id = defaultLoctag.id
                _appo.selected_location = defaultLoctag
            }

            if (deleteFree) {
                loctags = loctagsExceptFree
            }

            _appo.selectable_locations = loctags
            _appo.location_name = (loctags[0] || {})[`keyword`]
            if (_appo.location_name == `自由に場所を入力できます`) {
                _appo.location_name = null
                _appo.location_tag_id = null
            }
            _appo.selectable_location_tag_ids = loctagsExceptFree.map(l => l.id)
        } else {
            _appo.location_select_type = `registrable`
            _appo.selectable_locations = null
            _appo.selectable_location_tag_ids = null
            _appo.location_tag_id = null
            _appo.selected_location = null
            _appo.location_name = appo.location_name
        }
        // if (_appo.location_select_type == `registrable` && Util.isPresent(_appo.selected_location)) {
        //     _appo.location_tag_id = _appo.selected_location.id
        // }
        Logger(
            `Appointment. 判定後: ${_appo.location_select_type} _appo.location_tag_id: ${
                _appo.location_tag_id
            }, selected_location:${Util.output(_appo.selected_location)}`
        )
        return _appo
    }

    //
    static isSelectable(appo: Appointment) {
        if ([`selectable_or_registrable`, `selectable`].includes(appo.location_select_type)) return true
        return false
    }

    /**
     * location_select_typeと整合性をあわせます。
     * @param _appo
     *
     */
    static adjustLocations(_appo: Appointment, _loctags: LocationTag[] = null) {
        Logger(`Appointment.adjustLocations location_select_type:${_appo.location_select_type}`)
        if (Util.isBlank(_loctags) && Util.isPresent(_appo.selectable_locations)) _loctags = [..._appo.selectable_locations]

        // 選択できるのは１つだけなので、選択したらtrueにします.
        let isSelected = false
        Logger(`Appointment.adjustLocations isSelectable: ${Appointment.isSelectable(_appo)}`)
        if (Appointment.isSelectable(_appo) && Util.isPresent(_appo.selected_location)) {
            let loc = LocationTag.fetchFromJson([_appo.selected_location])[0]
            Logger(
                `Appointment.adjustLocations 選択中のものを追加します. loc:${Util.output(loc)}, _loctags.ids:${_loctags.map(
                    l => l.id
                )}`
            )

            let _loc = _loctags.find(l => l.id == loc.id)
            if (_loc) {
                Logger(`Appointment.adjustLocations _locありました`)
                _loc.selected = true
            } else {
                Logger(`Appointment.adjustLocations _locありませんでした`)
                loc.selected = true
                _loctags.push(loc)
            }
            isSelected = true
        }

        if (_appo.location_select_type == `selectable_or_registrable`) {
            let _lc = _loctags.find(l => l.public_id == `free`)
            if (_lc) {
                if (_appo.is_selected_free_location && !isSelected) {
                    _lc.selected = true
                }
            } else {
                let freeFormLoctag = LocationTag.createRegistrable()
                if (_appo.is_selected_free_location && !isSelected) {
                    freeFormLoctag.selected = true
                }
                _loctags.push(freeFormLoctag)
            }
        }
        Logger(`Appointment.adjustLocations _loctags:${Util.output(_loctags)}`)

        return _loctags
    }

    static fixVotedRoom(
        room: Room,
        pd: PossibleDate,
        voteType: string,
        parentRoomIds: string[],
        sampleId: string,
        text: string,
        force: boolean,
        textMemo: string,
        locationName: string,
        zoomAccs: CalendarTag[],
        newAttendees: RoomMember[],
        // オーナー以外の人が確定する場合、自分のparent_room_idを送信します.
        myParentRoom: Room = null,
        userInfo: RoomMember,
        // 担当者を指定する場合
        mag: MeetingAttendeesGroup = null,
        fAppoDic: any = null,
        endPoint: string = null
    ) {
        if (mag && mag.purpose_type == `temporary`) {
            mag.online_meeting_resources = zoomAccs
        }
        let dic = {
            id: room.id,
            start_time: pd.start_time,
            duration: pd.duration,
            vote_type: voteType,
            room_ids: parentRoomIds,
            sample_id: sampleId,
            text: text,
            is_force: force,
            memo: textMemo,
            location_name: locationName,
            online_meeting_resources: zoomAccs,
            new_attendees: newAttendees,
            group_id: mag ? mag.id : null,
            new_group: mag && mag.id ? null : mag,
            f_appo_dic: fAppoDic,
        }
        if (Util.isPresent(myParentRoom)) {
            dic[`parent_room_id`] = myParentRoom.id
        }

        if (Util.isPresent(endPoint)) {
        } else {
            endPoint = `fix_voted_rooms`
        }

        return axios({
            method: `post`,
            url: `${Util.prefixUrl}/public_rooms/${endPoint}`,
            data: dic,
        })
            .then(res => {
                Logger(`Appointment.fixVotedRoom res: ${res.data.message} ${Util.output(res.data)}`)
                if (res.data.message) {
                    Notice.message = `${res.data.message}`
                }
                const res_appo: Appointment = Appointment.fetchFromJson([res.data.appointment])[0]
                const room_ids = res.data.fixed_room_ids || []
                const newParentRooms = Room.fetchFromJson(res.data.new_attendees_parent_rooms, userInfo.user_id)

                return {
                    appo: res_appo,
                    room_ids: room_ids,
                    new_parent_rooms: newParentRooms,
                    status: res.status,
                    fixed_astags: res.data.fixed_astags,
                }
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)

                const res = err.response ? err.response : null
                // if (res && res.status == 400) {
                //     if (window.confirm(`予定が埋まっていて確定できませんでした。強制的に確定しますか？`)) {
                //         this.fixVotedRoom(
                //             room,
                //             pd,
                //             voteType,
                //             parentRoomIds,
                //             sampleId,
                //             text,
                //             true,
                //             textMemo,
                //             locationName,
                //             zoomAccs,
                //             newAttendees,
                //             myParentRoom,
                //             userInfo
                //         ).then(res => {
                //             return res
                //         })
                //     }
                // } else {
                //     NotificationControl.showErrorMessage(err)
                // }

                // if (res.code === "ng_052") {

                // } else {
                //     Notice.message = `日程調整情報をうまく更新できませんでした。後ほどお試しください。`
                // }
                return { appo: null, room_ids: null, new_parent_rooms: null, status: 400, fixed_astags: null }
            })
    }

    static cancelVotedRoom(room: Room, type: string, text: string) {
        let dic = {
            id: room.public_room_id,
            appo_id: room.current_appointment.id,
            type: type,
            text: text,
        }

        return axios({
            method: `post`,
            url: `${Util.prefixUrl}/public_rooms/cancel_voted_rooms`,
            data: dic,
        })
            .then(res => {
                Logger(`Appointment.cancelVotedRoom res: ${res.data.message} ${Util.output(res.data)}`)
                if (res.data.message) {
                    Notice.message = `${res.data.message}`
                }
                const res_appo: Appointment = Appointment.fetchFromJson([res.data.appointment])[0]
                const room_ids = res.data.canceled_room_ids || []

                return { appo: res_appo, room_ids: room_ids }
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static createJpFormat(dt: DateTime, duration: number) {
        let timeJpFormat = ``
        timeJpFormat += dt.toFormat(`M月d日`)
        let weekNum = dt.weekday
        if (weekNum > 6) weekNum -= 7
        timeJpFormat += `（${DateTag.weekNames()[weekNum]}）`
        timeJpFormat += `${dt.toFormat(`HH:mm`)}`
        let endt = dt.plus({ minutes: duration })
        timeJpFormat += `~${endt.toFormat(`HH:mm`)}`
        return timeJpFormat
    }
}
